import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );
  //<FooterNav />
  return (
    <footer {...props} className={classes}>
      <div className='container'>
        <div
          className={classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}
        >
          <div className='footer-top space-between text-xxs'>
            <FooterSocial />
          </div>
          <div className='footer-bottom space-between text-xxs invert-order-desktop'>
            <div className='footer-copyright'></div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
